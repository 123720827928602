html {
    overflow: hidden;
    height: 100%;
}

body {
    overflow: auto;
    height: 100%;
}

.App {
  text-align: center;
}


.App-logo {
  width: 600px;
  pointer-events: none;
}

@media (max-width:576px) {
  .App-logo {
    width: 300px;
  }
}

.socials {
  letter-spacing: 10px;
}


.socials a {
  text-decoration: none !important;
  color: white !important;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-weight: lighter;
}

.App-link {
  font-family: "Font Awesome 5 Brands";

  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
